import React, { useRef } from 'react';

// Libraries
import styled from 'styled-components';
import tw from 'twin.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';

const Container1 = styled.div`
  ${tw`
 mt-3
 py-2

`}
`;

const Section1 = styled.div`
  ${tw`
    flex
    gap-4
  `}
`;

const Heading1 = styled.h1`
  ${tw`
  font-normal
  pl-4
  text-left
  mt-2
  text-color-bright
 `}
  font-size: 1.5rem;
`;

const Description = styled.div`
  font-family: 'Taviraj';
  ${tw`
  w-3/4
  text-sm
  text-frame-gray
  font-normal
  col-span-2
  py-2
  pl-4
  text-left
  mb-2
  leading-4
  `}
`;

const ButtonContainer = styled.span`
  ${tw`
    pl-0
  `}
`;

const Button = styled.button`
  ${tw`
    bg-frame-xgray
    rounded-lg
    p-3
    text-white
    w-full
    border-none
    mt-5
  `}
`;

const Text = styled.div`
  font-family: 'Taviraj';
  ${tw`
  font-normal
  text-xl
  mt-2
  `}
`;

const Upload = ({ setUploadedImage, setHeight, setWidth }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the click event on the hidden input element
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedImage(URL.createObjectURL(event.target.files[0]));
      const i = new Image();
      i.onload = function () {
        const imageDimensions = [
          {
            height: i.height,
            width: i.width,
          },
        ];
        setHeight(imageDimensions[0].height);
        setWidth(imageDimensions[0].width);
      };

      i.src = URL.createObjectURL(event.target.files[0]);
    }
  };

  return (
    <div>
      {/* Hidden file input */}
      <input
        type='file'
        accept='image/*'
        style={{ display: 'none' }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />
      
      {/* Custom button for file upload */}
      <ButtonContainer>
          <Button onClick={handleButtonClick}>
            <label htmlFor='contained-button-file'>
              <FontAwesomeIcon icon={faUpload} style={{ color: '#fff', fontSize: '23px'}} />
            </label>
            <Text>
              Upload Image
            </Text>
          </Button>
        </ButtonContainer>
    </div>
  );
};

export default Upload;