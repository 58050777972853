import React, { useState, useRef } from 'react';

// Libraries
import useImage from 'use-image';
import styled from 'styled-components';
import tw from 'twin.macro';
import Helmet from 'react-helmet';

// Components
import Carousel from '../components/frameControllers/FrameCarousel';
import Head from '../components/shared/Head';
import Inputs from '../components/frameControllers/DetailsInput';
import Download from '../components/toolbox/Download';
import CanvasStage from '../components/canvas/Stage';
//import Card from '../components/Card';
import conpic from "../../static/concert.jpg"

// Assets
import frameData from '../../config/frameData';

const Container3 = styled.h1`
  ${tw`
   w-94
   pt-2
   m-auto
   sm:w-full
`}
`;

const C1 = styled.div`
  ${tw`
  mt-5
  mlg:mt-0
  `}

  background-color: #F0B01A;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
`;

const C4 = styled.div`
  ${tw`
  pt-8
  pl-5
  mlg:flex justify-center m-0
  `}
`;

const C3 = styled.div`
  ${tw`
  `}
`;

const C2 = styled.div`
  ${tw`
    flex
    gap-20
    mlg:grid
    mlg:gap-0
  `}
`;

const CarouselC = styled.h1`
  ${tw`
    w-94
    sm:w-full
    overflow-y-hidden
    mt-5
`}
  margin: 0px, 0px;
  padding: 2px;
  overflow-x: auto;
  white-space: nowrap;
`;

const Container = styled.div`
  ${tw`
    h-full
    grid
    font-roboto
    justify-center
    text-center
    justify-items-center
    items-center
    p-5
    overflow-y-hidden
    text-white
    `}

    background-color: #013C7E;
`;

const Heading = styled.div`
    font-family: 'Taviraj';
    font-size: 2em;
`;

const Yellow = styled.span`
  ${tw`
    text-frame-yellow
    `}
`;

const SlimText = styled.span`
  ${tw`
    font-light
    text-white
    `}
`;

const Howto = styled.div`
  margin-top:20px;
  font-family: 'Taviraj';
  line-height: 1.5em;
  font-weight:200;
`

const Desc = styled.div`
margin-top:20px;
font-family: 'Taviraj';
line-height: 1.5em;
font-weight:200;
color: #fff;
`
const FRAMES = {
  ONE: frameData.frames.ONE,
  // TWO: frameData.frames.TWO,
  // THREE: frameData.frames.THREE,
  // FOUR:frameData.frames.FOUR,
};

const Concert = styled.div`
 ${tw`
    flex
    justify-center
    text-center
    justify-items-center
    items-center
    p-5
    text-white
    lg:flex-col
    `}
  background: #1A1A1A;
`
const ImgConcert = styled.img`
  max-width: 500px;
  width: 100%;
  `
const ButtonTicket = styled.button`
  ${tw`
    px-14
    py-6
    text-xl
    text-white
    border-none
    outline-none
    hover:bg-frame-gray
    hover:cursor-pointer
    rounded
    `}
    font-family: 'Taviraj';
    background-color: #E32E33;
`;
const Footer = styled.div`
 ${tw`
    w-full
    bg-black
    text-white
    text-center
    p-3
    `}
`

const align = ['center', 'left', 'right'];
let i = 0;

const Frame = () => {
  const [selectedFrame, setSelectedFrame] = useState(FRAMES.ONE.bg);
  const [selectedForeground, setSelectedForeground] = useState(FRAMES.ONE.fg);
  const [uploadedImage, setUploadedImage] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [userName, setUserName] = useState('Your Name');
  const [guildName, setGuildName] = useState('Guild Name');
  const [checked, setchecked] = useState(false);
  const [checkedGuild, setcheckedGuild] = useState(false);
  const [fontFamily, setFontFamily] = useState(null);
  const [fontFamilyGuild, setFontFamilyGuild] = useState(null);
  const [fontColorGuild, setFontColorGuild] = useState(null);
  const [bgColor, setBgColor] = useState(null);
  const [bgColorGuild, setBgColorGuild] = useState(null);
  const [fontColor, setFontColor] = useState(null);
  const [alignment, setAlignment] = useState(align[0]);

  const handleAlignment = () => {
    setAlignment(align[i + 1]);
    // eslint-disable-next-line no-const-assign
    i += 1;

    if (i === 3) {
      setAlignment(align[i - 3]);
      i = 0;
    }
  };
  const handleChange = () => setchecked((prevCheck) => !prevCheck);
  const handleChangeGuild = () => setcheckedGuild((prevCheck) => !prevCheck);
  // setSelectedFrame
  const handleClick = (data) => {
    setSelectedFrame(data.bg)
    setSelectedForeground(data.fg)
  }

  const stageRef = useRef(null);
  // const [frameImg] = useImage(selectedFrame, 'Anonymous');
  const [bgImg] = useImage(selectedFrame, 'Anonymous');
  const [fgImg] = useImage(selectedForeground, 'Anonymous');
  const [image] = useImage(uploadedImage, 'Anonymous');

  return (
    <>
      <Helmet>
        <title>Profile Maker | Memetical</title>
        <meta name='Profile Maker | Memetical' contect='Profile generator for Memetical' />
      </Helmet>

      <Container>
        <C2>
          <C1>
            {typeof window !== 'undefined' && (
              <CanvasStage
                stageRef={stageRef}
                userName={userName}
                guildName={guildName}
                // frameImg={frameImg}
                bgImg={bgImg}
                fgImg={fgImg}
                image={image}
                alignment={alignment}
                fontColor={fontColor}
                checked={checked}
                checkedGuild={checkedGuild}
                fontFamily={fontFamily}
                bgColor={bgColor}
                bgColorGuild={bgColorGuild}
                fontColorGuild={fontColorGuild}
                fontFamilyGuild={fontFamilyGuild}
                height={height}
                width={width}
              />
            )}
            {/* <CarouselC>
              <Carousel frames={FRAMES} handleClick={handleClick} />
            </CarouselC> */}
            <Desc></Desc>
          </C1>

          <C3>
            <Container3>
              <Heading>Profile Maker</Heading>
              <Howto>
                แนะนำให้ใช้รูปภาพจัตุรัส (อัตราส่วน 1:1)<br />
                ขนาดแนะนำ 1000 x 1000 pixel<br />
                หากต้องการปรับขนาดรูปให้คลิกที่รูปตัวเองสองครั้ง ระบบปรับภาพจะปรากฏขึ้นมา
              </Howto>
              <Inputs
                handleChange={handleChange}
                handleChangeGuild={handleChangeGuild}
                checked={checked}
                checkedGuild={checkedGuild}
                setchecked={setchecked}
                setheckedGuild={setcheckedGuild}
                uploadedImage={uploadedImage}
                setUploadedImage={setUploadedImage}
                userName={userName}
                setUsername={setUserName}
                guildName={guildName}
                setGuildname={setGuildName}
                fontFamily={fontFamily}
                setFontFamily={setFontFamily}
                bgColor={bgColor}
                setBgColor={setBgColor}
                fontColor={fontColor}
                setFontColor={setFontColor}
                alignment={alignment}
                bgColorGuild={bgColorGuild}
                setBgColorGuild={setBgColorGuild}
                fontColorGuild={fontColorGuild}
                setFontColorGuild={setFontColorGuild}
                handleAlignment={handleAlignment}
                align={align}
                fontFamilyGuild={fontFamilyGuild}
                setFontFamilyGuild={setFontFamilyGuild}
                setHeight={setHeight}
                setWidth={setWidth}
              />
            </Container3>
            <Download stageRef={stageRef} />

          </C3>
        </C2>
      </Container>
      <Footer>
        Memetical is an unoffical Mystcial fansite. If you want reliable information please check at official account.
      </Footer>
    </>
  );
};

export default Frame;
